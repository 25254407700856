import { useFormik } from "formik";
import React, {useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../../Toaster";
import axiosPrivate from "../../../utils/http/axiosPrivate";
import classes from "./addContractorStyle.module.css";
import moment from "moment/moment";

//mobile validation check rajex
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// validation

const validationRules = {
  name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),
  email: Yup.string()
    .required("Email is required")
    .matches(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, "Invalid email format."),

  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(phoneRegExp, "Mobile number is not valid")
    .min(10, "Mobile number must be of 10 digits")
    .max(10, "Mobile number must be of 10 digits"),

  aadhar_card: Yup.string()
    .min(12, "Aadhar number must be of 12 digits")
    .max(12, "Aadhar number must be of 12 digits"),

  pan_card: Yup.string()
    .min(10, "Pan number must be of 10 digits")
    .max(10, "Pan number must be of 10 digits"),
  gst_no: Yup.string()
    .min(15, "GST number must be of 15 digits")
    .max(15, "GST number must be of 15 digits"),

  ifsc_code: Yup.string()
    .min(11, "Ifsc code must be of 11 digits")
    .max(11, "Ifsc code must be of 11 digits"),
};

const AddContractor = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [aadhar, setAadhar] = useState(null);
  const [pan, setPan] = useState(null);



  useEffect(() => {
    if (id) {
      axiosPrivate
        .get(`contractor/${id}`)
        .then((response) => {
          formik.setValues(response?.data?.data);
          formik.setFieldValue("aadhar", response?.data?.data?.uid_no);
          formik.setFieldValue("pan", response?.data?.data?.pan_no);
          if(response?.data?.data?.ifsc_code === null){
            formik.setFieldValue("ifsc_code", "");
          }
        })
        .catch((error) => {
          if (error.response) {
            // showErrorToast('Internal server error');

            if (error.response.status === 422) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                formik.setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      aadhar_card: "",
      aadhar_card_image: "",
      pan_card: "",
      pan_card_image: "",
      gst_no: "",
      account_no: "",
      bank_name: "",
      ifsc_code: "",
    },
    validationSchema: Yup.object(validationRules),

    onSubmit: (values, { setSubmitting, setFieldError }) => {
      let formData = new FormData();
      formData.append("name", values?.name);
      formData.append("email", values?.email);
      formData.append("mobile", values.mobile);
      formData.append("aadhar_card", values.aadhar_card);
      formData.append("aadhar_card_image", aadhar);
      formData.append("pan_card", values.pan_card);
      formData.append("pan_card_image", pan);
      formData.append("gst_no", values.gst_no);
      formData.append("account_no", values.account_no);
      formData.append("bank_name", values.bank_name);
      formData.append("ifsc_code", values.ifsc_code);
      
      

      if (id) {
        axiosPrivate
          .put(`contractor/${id}`, formData)
          .then((response) => {
            showSuccessToast("Contractor updated Successfully");
            navigate("/admin/contractor");
          })
          .catch((error) => {
            if (error.response) {
              showErrorToast(error?.response?.data?.errors?.msg);
              if (error.response.status === 422) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  setFieldError(
                    error.response.data.errors[i].param,
                    error.response.data.errors[i].msg
                  );
                }
              }
            }
          });
      } else {
        axiosPrivate
          .post("contractor", formData)
          .then((response) => {
            showSuccessToast("Contractor created successfully");
            navigate(-1);
          })
          .catch((error) => {
            if (error.response) {
              // showErrorToast('Internal server error');

              if (error.response.status === 422) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  setFieldError(
                    error.response.data.errors[i].param,
                    error.response.data.errors[i].msg
                  );
                }
              }
            }
          });
      }

      //   alert(JSON.stringify(values, null, 2));
    },
  });

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
  };

  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                      Contractor Details :
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      {/* name */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* email */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Email:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Enter Email Id *"
                            // onChange={formik.handleChange}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "email",
                                e.target.value.toLowerCase()
                              )
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* mobile number */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Mobile No.:
                          <span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="mobile"
                            name="mobile"
                            type="tel"
                            className="form-control"
                            placeholder="Enter Mobile No *"
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile}
                            maxLength={10}
                          />
                          {formik.touched.mobile && formik.errors.mobile ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.mobile}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* aadhar number */}
                      <div className="col-md-4 col-sm-12">
                        <label>Aadhar No. :</label>

                        <div className="form-group">
                          <input
                            id="aadhar_card"
                            name="aadhar_card"
                            type="tel"
                            className="form-control"
                            placeholder="Enter Aadhar No."
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.aadhar_card}
                            maxLength={12}
                          />
                          {formik.touched.aadhar_card &&
                          formik.errors.aadhar_card ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.aadhar_card}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* aadhar image*/}
                      <div className="col-md-4 col-sm-12">
                        <label>Aadhar Image:</label>

                        <div className="form-group">
                          <input
                            id="aadhar_card_image"
                            name="aadhar_card_image"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            placeholder="Enter Aadhar."
                            // onChange={(e) => setAaadhar(e.target.files[0])}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              const validImageTypes = [
                                "image/jpeg",
                                "image/png",
                                "image/jpg",
                              ];
                              if (file && validImageTypes.includes(file.type)) {
                                setAadhar(file);
                              } else {
                                e.target.value = null; // Reset the file input
                              }
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {/* {formik.touched.aadhar_card_imager &&
													formik.errors.aadhar_card_imager ? (
														<div className={classes.invalidDataError}>
															{formik.errors.aadhar_card_image}
														</div>
													) : null} */}
                        </div>
                        {formik.values?.aadhar_card_image && (
                          <img
                            width={100}
                            height={100}
                            src={`${process.env.REACT_APP_COMMON_IMAGE_URL}contractor/${formik.values?.aadhar_card_image}`}
                            alt="Aadhar Preview"
                            className="preview-image"
                          />
                        )}
                      </div>
                      {/* pan_no */}
                      <div className="col-md-4 col-sm-12">
                        <label>Pan No.:</label>
                        <div className="form-group">
                          <input
                            id="pan_card"
                            name="pan_card"
                            type="text"
                            className="form-control"
                            placeholder="Enter Pan No."
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pan_card}
                            maxLength={10}
                          />
                          {formik.touched.pan_card && formik.errors.pan_card ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.pan_card}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* pan image*/}
                      <div className="col-md-4 col-sm-12">
                        <label>Pan Image:</label>

                        <div className="form-group">
                          <input
                            id="pan_card_image"
                            name="pan"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            placeholder="Enter Pan Image"
                            // onChange={(e) => setPan(e.target.files[0])}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              const validImageTypes = [
                                "image/jpeg",
                                "image/png",
                                "image/jpg",
                              ];
                              if (file && validImageTypes.includes(file.type)) {
                                setPan(file);
                              } else {
                                e.target.value = null; // Reset the file input
                              }
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.values?.pan_card_image && (
                          <img
                            width={100}
                            height={100}
                            src={`${process.env.REACT_APP_COMMON_IMAGE_URL}contractor/${formik.values.pan_card_image}`}
                            alt="Pan Preview"
                            className="preview-image"
                          />
                        )}
                      </div>

                      {/* gst_number */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          GST No.:
                        </label>
                        <div className="form-group">
                          <input
                            id="gst_no"
                            name="gst_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter GST No."
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.gst_no}
                            maxLength={15}
                          />
                          {formik.touched.gst_no &&
                          formik.errors.gst_no ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.gst_no}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12"></div>
                      <div className="col-md-4 col-sm-12 mt-3">
                        <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                          Bank Details :
                        </strong>
                      </div>
                      <div className="col-md-4 col-sm-12"></div>
                      <div className="col-md-4 col-sm-12"></div>
                      {/* bank_account_no */}

                      <div className="col-md-4 col-sm-12">
                        <label>Bank Account No. :</label>

                        <div className="form-group">
                          <input
                            id="account_no"
                            name="account_no"
                            type="number"
                            className="form-control"
                            placeholder="Enter Bank Account No."
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.account_no}
                          />
                          {/* {formik.touched.account_no &&
														formik.errors.account_no ? (
															<div className={classes.invalidDataError}>
																{formik.errors.account_no}
															</div>
														) : null} */}
                        </div>
                      </div>
                      {/* ifsc_code */}
                      <div className="col-md-4 col-sm-12">
                        <label>Bank IFSC Code :</label>

                        <div className="form-group">
                          <input
                            id="ifsc_code"
                            name="ifsc_code"
                            type="text"
                            className="form-control"
                            placeholder="Enter Bank IFSC Code"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ifsc_code}
                            maxLength={11}
                          />
                          {formik.touched.ifsc_code &&
                          formik.errors.ifsc_code ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.ifsc_code}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* bank_name */}
                      <div className="col-md-4 col-sm-12">
                        <label>Bank Name :</label>

                        <div className="form-group">
                          <input
                            id="bank_name"
                            name="bank_name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Bank Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bank_name}
                          />
                          {/* {formik.touched.bank_name &&
														formik.errors.bank_name ? (
															<div className={classes.invalidDataError}>
																{formik.errors.bank_name}
															</div>
														) : null} */}
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12 submit-btn">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => navigate(-1)}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                            {id ? "Update" : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddContractor;
