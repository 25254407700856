import axiosPrivate from "../../../utils/http/axiosPrivate";
import { Link } from "react-router-dom/dist";

import { FaGreaterThan } from "react-icons/fa";
import React, { useEffect, useState } from "react";

import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { showErrorToast, showSuccessToast } from "../../../Toaster";
import AppointmentForm from "./AppointmentForm";
import ProbationForm from "./ProbationForm";
import RelievingForm from "./RelievingForm.js";
import BusinessCardForm from "./BusinessCardForm";
import ExpCumRelievingForm from "./ExpCumRelievingForm";
import SalarySlipForm from "./SalarySlipForm";

function Document() {
    const [employee, setemployee] = useState([]);
    const [employeeDetails, setemployeeDetails] = useState({});
    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [selectedDocumentType, setSelectedDocumentType] = useState(null);
    const [companies, setCompanies] = useState([]);


    useEffect(() => {
        axiosPrivate
            .get("company")
            .then((response) => {
                const companyData = response.data.data;
                setCompanies(companyData);
            })
            .catch((error) => {
                console.error("Error fetching companies:", error);
            });
    }, []);

    const getemployee = () => {
        const options = {
            headers: { "content-type": "application/json" },
        };
        axiosPrivate
            .get("users", options)
            .then((users) => {
                showSuccessToast("Attendance fetched successfully");
                setemployee(users?.data?.data);
            })
            .catch((error) => {
                if (error.response) {
                    showErrorToast("Internal server error");
                }
            });
    };

    const getEmployeeDetailsById = (id) => {
        const options = {
            headers: { "content-type": "application/json" },
        };

        axiosPrivate
            .get(`users/${selectedEmployee}`, options)
            .then((users) => {
                showSuccessToast("Attendance fetched successfully");
                setemployeeDetails(users?.data?.data);
            })
            .catch((error) => {
                if (error.response) {
                    showErrorToast("Internal server error");
                }
            });
    };

    useEffect(() => {
        getemployee();
    }, []);

    useEffect(() => {
        getEmployeeDetailsById();
    }, [selectedEmployee]);

    const options = employee
        .filter((emp) => (emp.role_name = "Employee"))
        .map((employee) => ({
            label: employee.name,
            value: employee.id,
        }));

    const documentTypes = [
        { label: "Appointment", value: "appointment" },
        { label: "Probation", value: "probation" },
        { label: "Relieving", value: "relieving" },
        { label: "Business Card", value: "business_card" },
        { label: "Exp Cum Relieving", value: "exp_cum_relieving" },
        { label: "Salary Slip", value: "salary_slip" },
    ];

    const documentOptions = documentTypes.map((docType) => ({
        label: docType.label,
        value: docType.value,
    }));

    return (
        <>
            <div className="mx-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <ul className="nav nav-tabs page-header-tab">
                        <li className="nav-item">
                            <div className="card-header">
                                <strong className="top-left-heading">Document</strong>
                                <ol className="headerroutes breadcrumb m-0 bg-none ">
                                    <li className="item color-gray">|</li>
                                    <li className="item">
                                        <Link to="/admin" className="route-heading item mx-2">
                                            Dashboard
                                        </Link>
                                    </li>
                                    <li className="item">
                                        <FaGreaterThan className="grater-than-route" />
                                    </li>
                                    <li className="route-heading item mx-2 mt-1">
                                        <Link to="/admin/document">Document</Link>
                                    </li>
                                </ol>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="section-body mt-3">
                <div className="container-fluid">
                    <div className="tab-content mt-3">
                        <div
                            className="tab-pane fade show active"
                            id="category-list"
                            role="tabpanel"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center ">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>
                                                    Employee Name:<span className="required">*</span>
                                                </label>
                                                <Dropdown
                                                    value={selectedEmployee}
                                                    options={options}
                                                    filter

                                                    filterBy="label"
                                                    onChange={(e) => {
                                                        setSelectedEmployee(e.value);
                                                    }}
                                                    placeholder="Select an Employee Name"

                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>

                                                    Document Type :{" "} <span className="required">*</span>
                                                </label>
                                                <Dropdown
                                                    value={selectedDocumentType}
                                                    filter
                                                    options={documentOptions}
                                                    onChange={(e) => setSelectedDocumentType(e.value)}
                                                    placeholder="Select Document Type"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {selectedDocumentType === "appointment" && (
                                        <AppointmentForm
                                            empCode={employeeDetails?.emp_code}
                                            documentType={selectedDocumentType}
                                            empAddress={employeeDetails?.address}
                                            empName={employeeDetails?.name}
                                        />
                                    )}
                                    {selectedDocumentType === "probation" && (
                                        <ProbationForm
                                            empCode={employeeDetails?.emp_code}
                                            documentType={selectedDocumentType}
                                            empDesignation={employeeDetails?.designation}
                                            companyData={employeeDetails?.company_name}
                                            empName={employeeDetails?.name}
                                        />
                                    )}

                                    {selectedDocumentType === "relieving" && (
                                        <RelievingForm
                                            empCode={employeeDetails?.emp_code}
                                            documentType={selectedDocumentType}
                                            empDesignation={employeeDetails?.designation}
                                            companyData={employeeDetails?.company_name}
                                            empDays={employeeDetails?.days}
                                            empreleavingDate={employeeDetails?.releavingDate}
                                            empName={employeeDetails?.name}
                                        />
                                    )}

                                    {selectedDocumentType === "business_card" && (
                                        <BusinessCardForm
                                            empCode={employeeDetails?.emp_code}
                                            empMobile={employeeDetails?.mobile}
                                            empDesignation={employeeDetails?.designation}
                                            companyData={employeeDetails?.company_name}
                                            documentType={selectedDocumentType}
                                            empName={employeeDetails?.name}
                                        />
                                    )}
                                    {selectedDocumentType === "exp_cum_relieving" && (
                                        <ExpCumRelievingForm
                                            empCode={employeeDetails?.emp_code}
                                            empMobile={employeeDetails?.mobile}
                                            empDesignation={employeeDetails?.designation}
                                            companyData={employeeDetails?.company_name}
                                            documentType={selectedDocumentType}
                                            empreleavingDate={employeeDetails?.releavingDate}
                                            emptodate={employeeDetails?.todate}
                                            empdoj={employeeDetails?.doj}
                                            empName={employeeDetails?.name}
                                        />
                                    )}
                                    {selectedDocumentType === "salary_slip" && (
                                        <SalarySlipForm
                                            empCode={employeeDetails?.emp_code}
                                            documentType={selectedDocumentType}
                                            empName={employeeDetails?.name}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Document;
