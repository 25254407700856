import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { button } from "primereact/button";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { showSuccessToast, showErrorToast } from "../../../Toaster";
import axiosPrivate from "../../../utils/http/axiosPrivate";


function Appointment({ empCode, documentType, empAddress, empName }) {
    const [address, setAddress] = useState(empAddress || "");
    const [date, setDate] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [responseUrl, setResponseUrl] = useState(null);
    const [isEmpNameValid, setIsEmpNameValid] = useState(true);



    useEffect(() => {
        // Check if empName is empty and update isEmpNameValid
        setIsEmpNameValid(!!empName);
    }, [empName]);
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const data = {
                emp_code: empCode,
                document_type: documentType,
                date: formatDate(date),
                address: address,
                emp_name: empName,
            };

            const response = await axiosPrivate.post("document-creation", data);
            showSuccessToast("Document created successfully.");
            setIsDialogOpen(true);
            setResponseUrl(response.data?.data?.url)
        } catch (error) {
            showErrorToast("An error occurred while creating the document.");
            console.error("Error:", error);
        }
    };
    function formatDate(inputDateStr) {
        let parsedDate = new Date(inputDateStr);
        let day = (parsedDate.getUTCDate() + 1).toString().padStart(2, "0");
        let month = (parsedDate.getUTCMonth() + 1).toString().padStart(2, "0");
        let year = parsedDate.getUTCFullYear();

        let outputDate = day + "/" + month + "/" + year;

        return outputDate;
    }
    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };
    const handleInputChange = (e) => {
        // console.log(e.target?.value)
        setAddress(e.target?.value)
    }
    return (
        <>
            <div className="d-flex align-items-center">
                <div className="col-lg-6">
                    <div className="form-group d-flex flex-column">
                        <label>
                            Address:<span className="required">*</span>
                        </label>
                        <InputText
                            id="address"
                            value={address}
                            filter
                            showClear
                            onChange={(e) => handleInputChange(e)}
                        />
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="form-group">
                        <label>
                            Date:<span className="required">*</span>
                        </label>
                        <Calendar
                            id="date"
                            value={date}
                            filter
                            showClear
                            onChange={(e) => setDate(e.value)}
                            dateFormat="dd/mm/yy"
                            showIcon
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-12 col-sm-12 submit-btn">
                <div className="form-group">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <button type="submit" className="btn btn-primary ml-2" disabled={!isEmpNameValid}>

                            Submit
                        </button>
                    </form>
                </div>
            </div>

            {isDialogOpen && (
                <div className="custom-dialog">
                    <div className="dialog-content">
                        <h2>Document Created</h2>
                        <p>Your document has been created successfully.</p>

                        {/* Add a download button with a URL here */}
                        {responseUrl && <a
                            href={responseUrl}
                            target="_blank"
                            download="document.pdf"
                            className="btn btn-primary"
                        >
                            Download Document
                        </a>}

                        <button onClick={closeDialog}>Close</button>
                    </div>
                </div>
            )}

        </>
    );
}

export default Appointment;
