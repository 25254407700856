import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// import { showErrorToast } from '../../../Toster';
import axiosPrivate from '../../utils/http/axiosPrivate';
import classes from './Roles.module.css';
import { showSuccessToast } from '../../Toaster';


const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
	name: Yup.string()
		.max(200, 'Name must not exceed 200 characters')
		.required('Role Name is required'),
};

const AddRoles = () => {
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);

	// get all roles
	useEffect(() => {
		axiosPrivate.get('roles').then((user_roles) => {
			setRoles(user_roles?.data?.data);
		});
	}, []);

	const formik = useFormik({
		initialValues: {
			name: '',
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			axiosPrivate
				.post('roles', values)
				.then((response) => {
					showSuccessToast('New role created successfully');
					navigate('/admin/roles');
				})
				.catch((error) => {
					if (typeof error.response.data.errors == 'array') {
						// showErrorToast(error.response.data.errors[0]?.msg);
					} else {
						// showErrorToast(error.response.data.errors?.msg ?? error.response.errors?.message);
					}
				});
		},
	});

	return (
		<div>
			<div className="section-body ">
				<div className="container-fluid">
					<div className="d-flex justify-content-between align-items-center ">
						{/* hearder breadcrumb */}
						<div className="card-header">
							<strong className="top-left-heading">
								Add Role & Permission
							</strong>

							<ol className="headerroutes breadcrumb m-0 bg-none ">
								<li className="item color-gray">|</li>
								<li className="item">
									<Link
										to="/admin/dashboard"
										className="route-heading item mx-2"
									>
										Dashboard
									</Link>
								</li>
								<li className="item">
									<FaGreaterThan className="grater-than-route" />
								</li>
								<li className="route-heading item mx-2 mt-1">
									<Link to="/admin/roles">Role & Permission</Link>
								</li>
								<li className="item">
									<FaGreaterThan className="grater-than-route" />
								</li>

								<li className="route-heading item mx-2 mt-1">
									Add Role & Permission
								</li>
							</ol>
						</div>
					</div>
				</div>

				<div className="tab-content ">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="row clearfix">
										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<input
													id="name"
													name="name"
													type="text"
													className="form-control"
													placeholder="Roles*"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.name}
												/>
												{formik.touched.name && formik.errors.name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.name}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-12">
											<hr className="mt-4" />
											<div className="table-responsive">
												<table className="table table-striped">
													<tbody></tbody>
												</table>
											</div>
											<button type="submit" className="btn btn-primary">
												Add
											</button>
											<button
												type="button"
												className="btn btn-secondary ml-2"
												data-dismiss="modal"
												onClick={() => navigate('/admin/roles')}
											>
												CLOSE
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddRoles;
