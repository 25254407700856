import DataTable2 from "../../../context/components/DataTable2";
import React, { useEffect, useState } from "react";
function SalaryForm({ salaryData }) {
  const [data, setData] = useState(salaryData);
  useEffect(() => {
    setData(salaryData);
  }, [salaryData]);
  const headers = [
    { name: "Type", field: "type", sortable: true, classKey: "" },
    { name: "Employee Name", field: "user_name", sortable: true, classKey: "" },
    {
      name: "Advance Date",
      field: "advance_date",
      sortable: true,
      classKey: "",
    },
    {
      name: "Advance Amount",
      field: "advance_amount",
      sortable: true,
      classKey: "",
    },
    {
      name: "Payment Type",
      field: "payment_type",
      sortable: true,
      classKey: "",
    },
    {
      name: "Payment Method",
      field: "payment_method",
      sortable: true,
      classKey: "",
    },
  ];
  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="category-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        {<DataTable2 lists={data} headers={headers} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SalaryForm;