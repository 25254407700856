import { useFormik, Formik, Form, Field, FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { FaPlus, FaTrash } from "react-icons/fa";
import axiosPrivate from "../../../utils/http/axiosPrivate";
import classes from "./punchKey.module.css";
import { showErrorToast } from "../../../Toaster";

// Validation schema for each punch key field
const validationSchema = Yup.object({
  punchKeys: Yup.array().of(
    Yup.object().shape({
      location: Yup.string().required("Location is required"),
      punch_in_key: Yup.string().required("Punch In key is required"),
      punch_out_key: Yup.string().required("Punch Out key is required"),
    })
  ),
});

const AddPunchKey = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // State to store initial values
  const [initialValues, setInitialValues] = useState({
    punchKeys: [{ punch_in_key: "", punch_out_key: "", location: "" }],
  });

  // Fetch existing data if ID is present
  useEffect(() => {
    if (id) {
      axiosPrivate
        .get(`punch-key-manager/${id}`)
        .then((res) => {
          const punchKeyData = res?.data?.data[0];
          setInitialValues({
            punchKeys: [
              {
                punch_in_key: punchKeyData?.punch_in_key || "",
                punch_out_key: punchKeyData?.punch_out_key || "",
                location: punchKeyData?.location || "",
              },
            ],
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true} // Enable reinitialization
              onSubmit={(values, { setFieldError }) => {
                if (id) {
                  axiosPrivate
                    .put(`punch-key-manager/${id}`, values)
                    .then((response) => {
                      navigate("/admin/punchKeyManager");
                    })
                    .catch((error) => {
						showErrorToast(error?.response?.data?.errors[0]?.msg);
                      if (error.response && error.response.status === 422) {
                        error.response.data.errors.forEach((err) => {
                          setFieldError(err.param, err.msg);
                        });
                      }
                    });
                } else {
                  axiosPrivate
                    .post("punch-key-manager", values)
                    .then((response) => {
                      navigate("/admin/punchKeyManager");
                    })
                    .catch((error) => {
                      showErrorToast(error?.response?.data?.errors[0]?.msg);
                      if (error.response && error.response.status === 422) {
                        error.response.data.errors.forEach((err) => {
                          setFieldError(err.param, err.msg);
                        });
                      }
                    });
                }
              }}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <div
                    className="tab-pane fade show active"
                    id="user-add"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div className="card-header">
                        <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                          {!id ? "Add Punch Key" : "Edit Punch Key"}
                        </strong>
                      </div>
                      <div className="card-body">
                        <FieldArray name="punchKeys">
                          {({ insert, remove, push }) => (
                            <div>
                              {values.punchKeys.length > 0 ? (
                                values.punchKeys.map((punchKey, index) => (
                                  <div className="row clearfix" key={index}>
                                    {/* Punch In Key */}
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                      <label>
                                        Punch In Key:
                                        <span className="required">*</span>
                                      </label>
                                      <Field
                                        name={`punchKeys.${index}.punch_in_key`}
                                        placeholder="Enter punch in key"
                                        className="form-control"
                                      />
                                      {touched.punchKeys &&
                                      touched.punchKeys[index] &&
                                      errors.punchKeys &&
                                      errors.punchKeys[index]?.punch_in_key ? (
                                        <div
                                          className={classes.invalidDataError}
                                        >
                                          {errors.punchKeys[index].punch_in_key}
                                        </div>
                                      ) : null}
                                    </div>

                                    {/* Punch Out Key */}
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                      <label>
                                        Punch Out Key:
                                        <span className="required">*</span>
                                      </label>
                                      <Field
                                        name={`punchKeys.${index}.punch_out_key`}
                                        placeholder="Enter punch out key"
                                        className="form-control"
                                      />
                                      {touched.punchKeys &&
                                      touched.punchKeys[index] &&
                                      errors.punchKeys &&
                                      errors.punchKeys[index]?.punch_out_key ? (
                                        <div
                                          className={classes.invalidDataError}
                                        >
                                          {
                                            errors.punchKeys[index]
                                              .punch_out_key
                                          }
                                        </div>
                                      ) : null}
                                    </div>

                                    {/* Location */}
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                      <label>
                                        Location:
                                        <span className="required">*</span>
                                      </label>
                                      <Field
                                        name={`punchKeys.${index}.location`}
                                        placeholder="Enter location"
                                        className="form-control"
                                      />
                                      {touched.punchKeys &&
                                      touched.punchKeys[index] &&
                                      errors.punchKeys &&
                                      errors.punchKeys[index]?.location ? (
                                        <div
                                          className={classes.invalidDataError}
                                        >
                                          {errors.punchKeys[index].location}
                                        </div>
                                      ) : null}
                                    </div>

                                    {/* Add/Remove Buttons */}
                                    {!id ? (
                                      <div>
                                        <label>&nbsp;</label>
                                        <div className="col-lg-1 col-md-2 col-sm-12 d-flex align-items-center">
                                          <button
                                            type="button"
                                            className="btn btn-primary mr-2"
                                            onClick={() =>
                                              push({
                                                punch_in_key: "",
                                                punch_out_key: "",
                                                location: "",
                                              })
                                            }
                                          >
                                            <FaPlus />
                                          </button>
                                          {values.punchKeys.length > 1 && (
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() => remove(index)}
                                            >
                                              <FaTrash />
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                ))
                              ) : (
                                <button
                                  type="button"
                                  onClick={() =>
                                    push({
                                      punch_in_key: "",
                                      punch_out_key: "",
                                      location: "",
                                    })
                                  }
                                >
                                  Add a Punch Key
                                </button>
                              )}
                              <div className="col-md-12 col-sm-12 submit-btn mt-4">
                                <div className="form-group">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => navigate(-1)}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-primary ml-2"
                                  >
                                    {id ? "Update" : "Submit"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPunchKey;
