import { Link, Navigate, useNavigate } from "react-router-dom";
import { FaGreaterThan } from "react-icons/fa";
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import axiosPrivate from "../../../utils/http/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../../Toaster";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
function AddAdvance() {
  const [advanceType, setAdvanceType] = useState("salary");
  const [showForm, setShowForm] = useState(true);
  const [empId, setEmpId] = useState("");
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [installment, setInstallment] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [employee, setemployee] = useState([]);
  const [employeeDetails, setemployeeDetails] = useState({});
  const [advanceDate, setAdvanceDate] = useState(null);
  const [paymentType, setPaymentType] = useState("");
  const [paymentmethod, setPaymentMethod] = useState("");
  const [paymentOptions, setPaymentOptions] = useState([
    { label: "Offline", value: "offline" },
    { label: "Online", value: "online" },
  ]);
  const [typeOptions, setTypeOptions] = useState([
    { label: "Employee name", value: "empname" },
    { label: "Others name", value: "others" },
  ]);
  const [type, setType] = useState("");
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([
    { label: "Net Banking", value: "netbanking" },
    { label: "UPI", value: "upi" },
    { label: "Debit Card", value: "debitcard" },
    { label: "Credit Card", value: "creditcard" },
    { label: "Cash", value: "cash" },
  ]);
  const [othersName , setOthersName] =useState("")
  const handleModalClose = () => {
    setIsModalVisible(false);
    setShowForm(false);
    setAdvanceType("");
  };

  const Navigate = useNavigate();
  const handleSaveChanges = () => {
    let updatedItemData = {
      type: type,
      advance_type: advanceType,
      emp_id: selectedEmployee ? selectedEmployee : "",
      other_name: othersName ? othersName : "",
      advance_amount: advanceAmount,
      payment_type: paymentType,
      payment_method: paymentmethod,
      advance_date: formatDate(advanceDate),
    };

    if (advanceType === "loan") {
      updatedItemData.installment = installment;
      updatedItemData.loan_amount = loanAmount;
    }

    axiosPrivate
      .post("/advance", updatedItemData)
      .then((response) => {
        if (response.status === 200) {
          showSuccessToast("Advance saved successfully.");

          // Open URL in a new tab using an anchor tag
          const url = response?.data?.data?.url;
          if (url) {
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.target = "_blank"; // Opens in a new tab
            anchor.click();
          }

          // Reset form fields
          setAdvanceType("");
          setEmpId("");
          setAdvanceAmount("");
          setInstallment("");
          setLoanAmount("");
          setPaymentType("");
          setPaymentMethod("");
          setAdvanceDate(null);
          setSelectedEmployee({});
          setOthersName("");
          setType("");

          setShowForm(false);
        } else {
          showErrorToast("Failed to save advance.");
        }
      })
      .catch((error) => {
        showErrorToast("An error occurred while saving advance.");
      });

    setIsModalVisible(false);
  };

  const getemployee = () => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("users", options)
      .then((users) => {
        // showSuccessToast("Attendance fetched successfully");
        setemployee(users?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          showErrorToast("Internal server error");
        }
      });
  };
  const getEmployeeDetailsById = (id) => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get(`users/${selectedEmployee}`, options)
      .then((users) => {
        // showSuccessToast("Attendance fetched successfully");
        setemployeeDetails(users?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          showErrorToast();
        }
      });
  };
  useEffect(() => {
    getemployee();
  }, []);
  useEffect(() => {
    getEmployeeDetailsById();
  }, [selectedEmployee]);
  const options = employee
    .filter((emp) => (emp.role_name = "Employee"))
    .map((employee) => ({
      label: employee.name,
      value: employee.id,
    }));
  function formatDate(inputDateStr) {
    if (!inputDateStr) {
      return null; // Handle null case if necessary
    }
    const parsedDate = new Date(inputDateStr);
    const year = parsedDate.getFullYear();
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = parsedDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return (
    <>
      <div className="mx-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <ul className="nav nav-tabs page-header-tab">
            <li className="nav-item">
              <div className="card-header">
                <strong className="top-left-heading">Add Advance Type</strong>
                <ol className="headerroutes breadcrumb m-0 bg-none">
                  <li className="item color-gray">|</li>
                  <li className="item">
                    <Link to="/admin" className="route-heading item mx-2">
                      Dashboard
                    </Link>
                  </li>
                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">
                    <Link to="/admin/advance">Add Advance Type</Link>
                  </li>
                </ol>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="advance-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <div className="form-group d-flex align-items-center">
                        <label className="mr-4">
                          <input
                            type="radio"
                            value="salary"
                            checked={advanceType === "salary"}
                            onChange={() => {
                              setAdvanceType("salary");
                              setShowForm(true);
                            }}
                          />
                          Salary
                        </label>
                        <label>
                          <input
                            type="radio"
                            value="loan"
                            checked={advanceType === "loan"}
                            onChange={() => {
                              setAdvanceType("loan");
                              setShowForm(true);
                            }}
                          />
                          Loan
                        </label>
                      </div>
                    </div>
                  </div>
                  {showForm && (
                    <div className="p-grid p-fluid">
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <label>
                            Type:<span className="required">*</span>
                          </label>
                          <Dropdown
                            value={type}
                            options={typeOptions}
                            onChange={(e) => {
                              setType(e.value);
                              setEmpId(e.value);
                            }}
                            placeholder="Select an type"
                          />
                        </div>
                        {type === "empname" && (
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <label>
                              Employee Name:<span className="required">*</span>
                            </label>
                            <Dropdown
                              value={selectedEmployee}
                              options={options}
                              filter
                              showClear
                              filterBy="label"
                              onChange={(e) => {
                                setSelectedEmployee(e.value);
                                setEmpId(e.value);
                              }}
                              placeholder="Select an Employee Name"
                            />
                          </div>
                        )}
                        {type === "others" && (
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <label>
                              Name:<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={othersName}
                              onChange={(e) => setOthersName(e.target.value)}
                              placeholder="Enter Name"
                            />
                          </div>
                        )}
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <label>Date:</label>
                          <Calendar
                            id="date"
                            value={advanceDate}
                            filter
                            showClear
                            onChange={(e) => setAdvanceDate(e.value)}
                            dateFormat="dd/mm/yy"
                            showIcon
                          />
                        </div>
                        {advanceType === "salary" && (
                          <>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label>Advance Amount:</label>
                              <InputNumber
                                value={advanceAmount}
                                onValueChange={(e) =>
                                  setAdvanceAmount(e.target.value)
                                }
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label>Payment Type:</label>
                              <Dropdown
                                value={paymentType}
                                options={paymentOptions}
                                onChange={(e) => {
                                  setPaymentType(e.value);
                                  if (e.value === "offline") {
                                    setPaymentMethod("cash");
                                  } else {
                                    setPaymentMethod("");
                                    setPaymentMethodOptions([
                                      {
                                        label: "Net Banking",
                                        value: "netbanking",
                                      },
                                      { label: "UPI", value: "upi" },
                                      {
                                        label: "Debit Card",
                                        value: "debitcard",
                                      },
                                      {
                                        label: "Credit Card",
                                        value: "creditcard",
                                      },
                                    ]);
                                  }
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label>Payment Method:</label>
                              <Dropdown
                                value={paymentmethod}
                                options={paymentMethodOptions}
                                onChange={(e) => setPaymentMethod(e.value)}
                                disabled={paymentType === "offline"}
                              />
                            </div>
                          </>
                        )}
                        {advanceType === "loan" && (
                          <>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label>Installment:</label>
                              <InputNumber
                                value={installment}
                                onValueChange={(e) =>
                                  setInstallment(e.target.value)
                                }
                              />
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <label>Loan Amount:</label>
                              <InputNumber
                                value={loanAmount}
                                onValueChange={(e) =>
                                  setLoanAmount(e.target.value)
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>

                      <div className="col-md-12 col-sm-12 mt-3 submit-btn">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleModalClose}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                            onClick={handleSaveChanges}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddAdvance;
