import React, { useState, useEffect } from "react";
import axiosPrivate from "../../../utils/http/axiosPrivate";
import { showSuccessToast, showErrorToast } from "../../../Toaster";

function SalarySlipForm({ empCode, documentType, empName }) {
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [responseUrl, setResponseUrl] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEmpNameValid, setIsEmpNameValid] = useState(true);

    const months = [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" },
    ];

    useEffect(() => {
        // Check if empName is empty and update isEmpNameValid
        setIsEmpNameValid(!!empName);
    }, [empName]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = {
                emp_code: empCode,
                document_type: documentType,
                month: month,
                year: year,
            };

            const response = await axiosPrivate.post("document-creation", data);
            console.log("data respnse", response.data?.data);
            showSuccessToast("Document created successfully.");
            setIsDialogOpen(true);
            setResponseUrl(response.data?.data?.url);
        } catch (error) {
            showErrorToast("An error occurred while creating the document.");
            console.error("Error:", error);
        }
    };

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <>
            <div className="d-flex align-items-center">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>
                            Month:<span className="required">*</span>
                        </label>
                        <select
                            id="month"
                            name="month"
                            className="form-control"
                            onChange={(e) => setMonth(e.target.value)}
                            value={month}
                        >
                            <option value="" disabled>Select Month</option>
                            {months.map((m) => (
                                <option key={m.value} value={m.value}>
                                    {m.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>
                            Year:<span className="required">*</span>
                        </label>
                        <input
                            id="year"
                            name="year"
                            type="text"
                            className="form-control"
                            onChange={(e) => setYear(e.target.value)}
                            value={year}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-12 col-sm-12 submit-btn">
                <div className="form-group">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <button type="submit" className="btn btn-primary ml-2" disabled={!isEmpNameValid}>
                            Submit
                        </button>
                    </form>
                </div>
            </div>

            {isDialogOpen && (
                <div className="custom-dialog">
                    <div className="dialog-content">
                        <h2>Document Created</h2>
                        <p>Your document has been created successfully.</p>

                        {/* Add a download button with a URL here */}
                        {responseUrl && (
                            <a
                                href={responseUrl}
                                download="document.pdf"
                                target="_blank"
                                className="btn btn-primary"
                            >
                                Download Pdf
                            </a>
                        )}

                        <button onClick={closeDialog}>Close</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default SalarySlipForm;
