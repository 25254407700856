import { useContext, useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast } from "../../../Toaster";
import axiosPrivate from "../../../utils/http/axiosPrivate";
import AuthContext from "../../../context/auth/AuthContext";
import DataTable2 from "../../../context/components/DataTable2";
import { FaGreaterThan } from "react-icons/fa";
import styles from "./users.module.css";

const UserList = ({ authPermission }) => {
  const { authPermissions } = useContext(AuthContext);
  const [userState, setUserState] = useState([]);
  const [company, setCompany] = useState([]);
  const [contractor, setContractor] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [contractorId, setContractorId] = useState(null);

  const navigate = useNavigate();

  const headers = [
    { name: "NAME", field: "name", sortable: true, classKey: "" },
    { name: "EMAIL", field: "email", sortable: true, classKey: "" },
    { name: "CONTACT NUMBER", field: "mobile", sortable: true, classKey: "" },
    { name: "ROLE", field: "role_name", sortable: true, classKey: "" },
    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["name", "email", "role_name"];

  const getUsers = () => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get(`users?companyId=${companyId}&contractorId=${contractorId}`, options)
      .then((users) => {
        const usersData = users?.data?.data?.map((value, key) => {
          let buttons = [];
          // if (true && authPermissions.includes('Staff-Edit'))
          buttons.push(
            <Link
              key="editButton##1"
              type="button"
              to={`/admin/user/edit/${value.id}`}
              className="btn btn-icon"
              title="Edit"
            >
              <BiEdit style={{ color: "green" }} size={18} />
            </Link>
          );
          // if (true && authPermissions.includes('Role & Permission-Edit'))
          // buttons.push(
          // 	<Link
          // 		key="userButton##1"
          // 		type="button"
          // 		to={`/admin/permissions/${value.id}/${value.role}`}
          // 		className="btn btn-icon"
          // 		title="Permission"
          // 	>
          // 		<i className="fa fa-user"></i>
          // 	</Link>
          // );
          // if (true && authPermissions.includes(`Staff-Delete`))
          buttons.push(
            <button
              key="deleteButton##1"
              type="button"
              data-id={value.id}
              onClick={() => userDeleteHandler(value.id)}
              className="btn btn-icon js-sweetalert"
              title="Delete"
            >
              <RiDeleteBinLine style={{ color: "red" }} size={17} />
            </button>
          );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });

        setUserState(usersData);
      })
      .catch((error) => {
        if (error.response) {
          setUserState([])
          showErrorToast(error?.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("company")
      .then((res) => {
        setCompany(res?.data?.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("contractor")
      .then((res) => {
        setContractor(res?.data?.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const userDeleteHandler = (id) => {
    axiosPrivate
      .delete(`users/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          navigate("/admin/user");
          getUsers();
          showSuccessToast("User Deleted Successfully!");
        }
      })
      .catch((error) => {
        if (error.response) {
          showErrorToast("Internal server error");
        }
      });
  };

  return (
    <>
      <div className="mx-5">
        <div className="d-flex justify-content-between align-items-center ">
          <ul className="nav nav-tabs page-header-tab mt-3 text-dark">
            <li className="nav-item navSpace">
              <div className="card-header headerSpace">
                <div className="breadcrumb-area">
                  <h1
                    className="card-title text-dark"
                    style={{ fontWeight: "700", fontSize: "18px" }}
                  >
                    Users
                  </h1>
                  <ol className="headerroutes breadcrumb m-0 bg-none ">
                    <li className="item color-gray">|</li>
                    <li className="item mx-2" style={{ fontSize: "14px" }}>
                      {/* <Link to="#" className="item mx-2" style={{ fontSize: "14px" }}> */}
                      Dashboard
                      {/* </Link> */}
                    </li>
                    <li className="item">
                      <FaGreaterThan style={{ fontSize: "14px" }} />
                    </li>
                    <li className="item mx-2 mt-1" style={{ fontSize: "14px" }}>
                      Users list
                    </li>
                  </ol>
                </div>
              </div>
            </li>
          </ul>
          <div className="header-action">
            <Link to="/admin/user/add" className="text-decoration-none">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fe fe-plus mr-2" />
                Add
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="mb-4">
                    <div className="d-flex">
                      <div>
                        <select
                          id="company_id"
                          name="company_id"
                          className={`form-control ${styles.dropdownHeight}`}
                          onChange={(e) => {
                            setCompanyId(e.target.value);
                          }}
                        >
                          <option
                            value=""
                            selected
                            label="Select Company"
                            disabled
                          />
                          {company.map((res) => (
                            <option value={res.id} label={res.name} />
                          ))}
                        </select>
                      </div>
                      <div>
                        <select
                          id="contractor_id"
                          name="contractor_id"
                          className={`form-control ${styles.dropdownHeight}`}
                          onChange={(e) => {
                            setContractorId(e.target.value);
                          }}
                        >
                          <option
                            value=""
                            selected
                            label="Select Contractor"
                            disabled
                          />
                          {contractor.map((res) => (
                            <option value={res.id} label={res.name} />
                          ))}
                        </select>
                      </div>
                      <div>
                        <button className="btn btn-primary" onClick={getUsers}>
                          Fetch Users
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {userState !== null && (
                      <DataTable2
                        lists={userState}
                        headers={headers}
                        searchItems={searchItems}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
