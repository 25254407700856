import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosPrivate from "../../utils/http/axiosPrivate";
import PermissionTableTd from "./PermissionTableTd";
import { showSuccessToast } from "../../Toaster";
// import LoaderContext from "../../../context/Loader/LoaderContext";

const validationRules = {};

const RolePermission = () => {
  // const { setIsLoading } = useContext(LoaderContext);
  let { roleId } = useParams();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setRolePermissions] = useState([]);
  const [permissionDistinctName, setPermissionDistinctName] = useState([]);
  const [rolePermissionsId, setRolePermissionsId] = useState([]);

  useEffect(() => {
    axiosPrivate.get("permissions").then((fetched_permissions) => {
      setPermissions(fetched_permissions?.data?.data);
    }).catch((error) => {
      console.log(error);
    });

    axiosPrivate.get("permissions/distinct/name").then((distinctName) => {
      setPermissionDistinctName(distinctName?.data?.data);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    // setIsLoading(true);
    axiosPrivate
      .get(`role-has-permissions/${roleId}`)
      .then((fetched_role_permissions) => {
        setRolePermissions(fetched_role_permissions?.data?.data);
        if (fetched_role_permissions.data.data.length) {
          let rolePermissionsArray = [];
          for (let m = 0; m < fetched_role_permissions.data.data.length; m++) {
            rolePermissionsArray.push(
              fetched_role_permissions.data.data[m].permission_id
            );
          }
          setRolePermissionsId(rolePermissionsArray);
        }
        // setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        // setIsLoading(false);
      });
  }, [roleId]);

  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem('userData'));
    // axiosPrivate.get(`permissions/${user?.userId}`).then((fetched_permissions) => {
    //   setPermissions(fetched_permissions?.data?.data);
    // }).catch((error) => {
    //   console.log(error);
    // });

    axiosPrivate.get("permissions/distinct/name").then((distinctName) => {
      setPermissionDistinctName(distinctName?.data?.data);
    }).catch((error) => {
      console.log(error);
    });
  }, [rolePermissionsId]);

  const modifyPermissionIdArray = async (permission_id, action) => {
    if (action === false) {
      const index = rolePermissionsId.indexOf(permission_id);
      rolePermissionsId.splice(index, 1);
    } else {
      rolePermissionsId.push(permission_id);
    }
  };

  const submitHandler = async (event) => {
    // setIsLoading(true);
    event.preventDefault();
    let values = {
      role_id: roleId,
      permission_id: rolePermissionsId,
    };

    await axiosPrivate
      .post(`role-has-permissions`, values)
      .then((response) => {
        showSuccessToast("Role Permissions added successfully");
        // setIsLoading(false);
        navigate("/admin/roles");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status !== 201) {
          }
        }
        // setIsLoading(false);
      });
  };

  const title = ["List", "Create", "Edit", "View", "Delete"];
  let checked = false;


  return (
    <div className="section-body ">
      <div className="container-fluid">
        {/* hearder breadcrumb */}
        <div className="card-header">
          <strong className="top-left-heading"> Role Permission</strong>

          <ol className="headerroutes breadcrumb m-0 bg-none ">
            <li className="item color-gray">|</li>
            <li className="item">
              <Link to="/admin" className="route-heading item mx-2">
                Dashboard
              </Link>
            </li>
            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>
            <li className="route-heading item mx-2 mt-1">
              <Link to="/admin/roles">Role & Permission</Link>
            </li>

            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>
            <li className="route-heading item mx-2 mt-1">Role Permission</li>
          </ol>
        </div>

        <div className="tab-content ">
          <form onSubmit={submitHandler}>
            <div
              className="tab-pane fade show active"
              id="user-permissions"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="row clearfix">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th />
                              {title.map((name, key) => (
                                <th key={key}>{name}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>{permissionDistinctName.map((distinct, index) => {
                            return (
                              <tr key={index}>
                                <td>{distinct.name}</td>
                                {permissions.map((permission, index1) => {
                                  if (rolePermissionsId.includes(String(permission.id))) {
                                    checked = true;
                                  } else {
                                    checked = false;
                                  }
                                  if (permission.name === distinct.name) {
                                    if (permission.action == "List") {
                                      return (
                                        <PermissionTableTd
                                          tdKey={index1}
                                          key={index1}
                                          id={permission.id}
                                          checked={checked}
                                          modifyPermissionIdArray={modifyPermissionIdArray}
                                        />
                                      );
                                    }
                                    if (permission.action === "Create") {
                                      return (
                                        <PermissionTableTd
                                          tdKey={index1}
                                          key={index1}
                                          id={permission.id}
                                          checked={checked}
                                          modifyPermissionIdArray={modifyPermissionIdArray}
                                        />
                                      );
                                    }
                                    if (permission.action === "Edit") {
                                      return (
                                        <PermissionTableTd
                                          tdKey={index1}
                                          key={index1}
                                          id={permission.id}
                                          checked={checked}
                                          modifyPermissionIdArray={modifyPermissionIdArray}
                                        />
                                      );
                                    }
                                    if (permission.action === "View") {
                                      return (
                                        <PermissionTableTd
                                          tdKey={index1}
                                          key={index1}
                                          id={permission.id}
                                          checked={checked}
                                          modifyPermissionIdArray={modifyPermissionIdArray}
                                        />
                                      );
                                    }
                                    if (permission.action === "Delete") {
                                      return (
                                        <PermissionTableTd
                                          tdKey={index1}
                                          key={index1}
                                          id={permission.id}
                                          checked={checked}
                                          modifyPermissionIdArray={modifyPermissionIdArray}
                                        />
                                      );
                                    }
                                  }
                                })}
                              </tr>
                            );
                          })}</tbody>
                        </table>
                      </div>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/roles")}
                      >
                        CLOSE
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary float-right"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RolePermission;
