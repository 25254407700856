import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import slider1 from '../../assets/images/slider1.svg';
import slider2 from '../../assets/images/slider2.svg';
import slider3 from '../../assets/images/slider3.svg';
import { useNavigate } from "react-router-dom";
import AuthContext from '../../context/auth/AuthContext';
import axiosPrivate from '../../utils/http/axiosPrivate';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const SignIn = () => {
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const loginSchma = Yup.object({
    email: Yup.string().email().required("Email is Required"),
    password: Yup.string().required(" Password is Required"),
  })

  const loginForm = {
    email: "",
    password: "",
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: loginForm,
    validationSchema: loginSchma,
    onSubmit: ((data, values) => {
      axiosPrivate.post('login', data)
        .then((res) => {
          localStorage.setItem('userData', JSON.stringify(res.data));
          localStorage.setItem('userDetails', JSON.stringify(res.data));
          localStorage.setItem('userRefreshToken', JSON.stringify(res?.data?.refresh_token));
          setUser(res.data);
          // navigate('/admin');
          window.location.reload();
        })

        .catch((err) => {
          console.log(err);
          
        })
    })

  })

  useEffect(() => {
    if (user?.token) {
      navigate("/admin", { replace: true });
    }
  }, []);

  const onSubmit = async (data) => {
    await axiosPrivate.post('login', data)
      .then((res) => {
        setUser(res.data);
        localStorage.setItem('userData', JSON.stringify(res.data));
        setTimeout(() => {
          if (res.role == 'client') {
            navigate('/')
          }

          else {
            navigate('/admin')
          }
        }, 1000);
      })

      .catch((err) => {
        console.log(err);
      })
  };



  

  return (
    <Fragment>
      <div className="auth_left">
        <div className="card">
          {/* <div className="text-center mb-2">
            <Link className="header-brand" to="/">
              <img src='./../assets/images/home/logo.webp' />
            </Link>
          </div> */}
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="card-title">Login to your account</div>

              <div className="form-group">
                <label className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  id="id"
                  className="form-control"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                />
                {touched.email && errors.email ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.email}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label className="form-label">
                  Password
                  {/* <Link className="float-right small" to="/forgotpassword">
                    I forgot password
                  </Link> */}
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                  // value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Password"
                />
                {touched.password && errors.password ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.password}
                  </div>
                ) : null}
              </div>
              {/* <div className="form-group">
                <label className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" />
                  <span className="custom-control-label">Remember me</span>
                </label>
              </div> */}
              <div className="form-footer">
                <input type="submit" className="btn btn-primary btn-block" value="login" />
              </div>
            </form>
          </div>
          {/* <div className="text-center text-muted">
            Don't have account yet? <Link to="/auth/signup">Sign Up</Link>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default SignIn;