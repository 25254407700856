import React, { useState, useEffect } from "react";

import { Calendar } from "primereact/calendar";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { showSuccessToast, showErrorToast } from "../../../Toaster";
import axiosPrivate from "../../../utils/http/axiosPrivate";

function Appointment({
    empCode,
    documentType,
    companyData,
    empName,
    empDesignation,
    empdoj,
    emptodate,
    empreleavingDate,
}) {
    const [date, setDate] = useState(null);
    const [companyName, setCompanyName] = useState(companyData || "");
    const [designation, setDesignation] = useState(empDesignation || "");
    const [releavingDate, setreleavingDate] = useState(empreleavingDate || "");
    const [todate, settodate] = useState(emptodate || "");
    const [doj, setdoj] = useState(empdoj || "");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [responseUrl, setResponseUrl] = useState(null);
    const [isEmpNameValid, setIsEmpNameValid] = useState(true);
    useEffect(() => {
        setCompanyName(companyData || "");
        setDesignation(empDesignation || "");
        setreleavingDate(empreleavingDate || "");
        settodate(emptodate || "");
        setdoj(empdoj || "");
    }, [companyData, empDesignation, empreleavingDate, emptodate, empdoj]);



    useEffect(() => {
        // Check if empName is empty and update isEmpNameValid
        setIsEmpNameValid(!!empName);
    }, [empName]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = {
                emp_code: empCode,
                document_type: documentType,
                date: formatDate(date),
                fromDate: doj,
                designation: designation,
                releavingDate: releavingDate,
                todate: todate,
                emp_name: empName,
            };

            const response = await axiosPrivate.post("document-creation", data);
            showSuccessToast("Document created successfully.");
            setIsDialogOpen(true);
            setResponseUrl(response.data?.data?.url);
        } catch (error) {
            showErrorToast("An error occurred while creating the document.");
            console.error("Error:", error);
        }
    };

    function formatDate(inputDateStr) {
        let parsedDate = new Date(inputDateStr);
        let day = (parsedDate.getDate() + 1).toString().padStart(2, "0");
        let month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
        let year = parsedDate.getFullYear();

        let outputDate = day + "/" + month + "/" + year;

        return outputDate;
    }
    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <>
            <div className="d-flex align-items-center">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>
                            Company Name:<span className="required">*</span>
                        </label>
                        <input
                            id="company_name
                            "
                            name="company_name
                            "
                            type="text"
                            className="form-control"
                            readOnly
                            onChange={(e) => setCompanyName(e.target.value)}
                            value={companyName}
                        />
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="form-group">
                        <label>
                            Designation:<span className="required">*</span>
                        </label>
                        <input
                            id="designation"
                            name="designation"
                            type="text"
                            readOnly
                            className="form-control"
                            onChange={(e) => setDesignation(e.target.value)}
                            value={designation}
                        />
                    </div>
                </div>
            </div>

            <div className="d-flex align-items-center">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>
                            From Date:<span className="required">*</span>
                        </label>
                        <Calendar
                            id="doj"
                            value={doj}
                            filter
                            showClear
                            onChange={(e) => setdoj(e.value)}
                            dateFormat="dd/mm/yy"
                            showIcon
                        />
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="form-group">
                        <label>
                            To Date:<span className="required">*</span>
                        </label>
                        <Calendar
                            id="todate"
                            value={todate}
                            filter
                            showClear
                            onChange={(e) => settodate(e.value)}
                            dateFormat="dd/mm/yy"
                            showIcon
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>
                            Select Releaving Date:<span className="required">*</span>
                        </label>
                        <Calendar
                            id="releavingDate"
                            value={releavingDate}
                            filter
                            showClear
                            onChange={(e) => setreleavingDate(e.value)}
                            dateFormat="dd/mm/yy"
                            showIcon
                        />
                    </div>
                </div>
            </div>

            <div className="col-md-12 col-sm-12 submit-btn">
                <div className="form-group">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <button type="submit" className="btn btn-primary ml-2" disabled={!isEmpNameValid}>
                            Submit
                        </button>
                    </form>
                </div>
            </div>
            {isDialogOpen && (
                <div className="custom-dialog">
                    <div className="dialog-content">
                        <h2>Document Created</h2>
                        <p>Your document has been created successfully.</p>

                        {/* Add a download button with a URL here */}
                        {responseUrl && (
                            <a
                                href={responseUrl}
                                download="document.pdf"
                                target="_blank"
                                className="btn btn-primary"
                            >
                                Download Document
                            </a>
                        )}

                        <button onClick={closeDialog}>Close</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default Appointment;
