import { useState } from 'react'

import styles from "./toggle.module.css"

export const Toggle = ({ label, toggled, onClick }) => {
    const [isToggled, toggle] = useState(toggled)

    const callback = () => {
        toggle(!isToggled)
        onClick(!isToggled)
    }

    return (
        <label className={styles.toggleLabel}>
            <input className={styles.toggleInput} type="checkbox" defaultChecked={isToggled} onClick={callback} />
            <span className={styles.toggleSpan}/>
            <strong className={styles.toggleStrong}>{label}</strong>
        </label>
    )
}