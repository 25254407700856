import { useState, useCallback, useEffect } from "react";
import AuthContext from "./AuthContext";
import axiosPrivate from "../../utils/http/axiosPrivate";
import { useNavigate } from "react-router";

const AuthState = (props) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userData") || "{}")
  );
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails") || "{}")
  );
  const [authPermissions, setAuthPermissions] = useState([]);

  useEffect(() => {
    console.log("user", userDetails);
    if (localStorage.getItem("userDetails")) {
      axiosPrivate
        .get(`role-has-permissions/${userDetails?.role}`)
        .then((fetched_user_permissions) => {
          if (fetched_user_permissions.data.data.length) {
            let mergedPermissions = [];
            for (
              let i = 0;
              i < fetched_user_permissions.data.data.length;
              i++
            ) {
              mergedPermissions.push(
                `${fetched_user_permissions.data.data[i].permission_name}-${fetched_user_permissions.data.data[i].permission_action}`
              );
            }

            console.log(mergedPermissions);
            setAuthPermissions(mergedPermissions);
          }
        })
        .catch((error) => {
          if (error.response) {
            //response status is an error code
            console.log(error.response.status);
          } else if (error.request) {
            //response not received though the request was sent
            console.log(error.request);
          } else {
            //an error occurred when setting up the request
            console.log(error.message);
          }
        });
    }
    else {
      navigate("/auth/signin");
    }
  }, [userDetails]);

  function changeAuthPermissions() {
    if (userDetails != "" && userDetails.id != undefined) {
      axiosPrivate
        .get(`user-has-permissions/user/${userDetails.id}`)
        .then((fetched_user_permissions) => {
          if (fetched_user_permissions.data.data.length) {
            let mergedPermissions = [];
            for (
              let i = 0;
              i < fetched_user_permissions.data.data.length;
              i++
            ) {
              mergedPermissions.push(
                `${fetched_user_permissions.data.data[i].name}-${fetched_user_permissions.data.data[i].action}`
              );
            }
            setAuthPermissions(mergedPermissions);
          }
        })
        .catch((error) => {
          if (error.response) {
            //response status is an error code
            console.log(error.response.status);
          } else if (error.request) {
            //response not received though the request was sent
            console.log(error.request);
          } else {
            //an error occurred when setting up the request
            console.log(error.message);
          }
        });
    }
  }

  function checkPermissionExists(permission) {
    if (authPermissions.includes(permission)) {
      return true;
    }
    return false;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        authPermissions,
        setAuthPermissions,
        changeAuthPermissions,
        checkPermissionExists,
        userDetails,
        setUserDetails,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
