import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast, showSuccessToast } from '../../../Toaster';
import axiosPrivate from '../../../utils/http/axiosPrivate';
import classes from './holiday.module.css';

//mobile validation check rajex
const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// validation
const validationRules = {
	title: Yup.string()
		.max(120, 'title must not exceed 120 characters')
		.required('Title is required'),
	type: Yup.string().required('Type is required'),
	date: Yup.date().required('Date is required'),
};

const AddHoliday = () => {

	const navigate = useNavigate();

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			axiosPrivate.get(`holiday/${id}`).then((res) => {
				formik.setFieldValue('title', res?.data?.data[0]?.title);
				formik.setFieldValue('type', res?.data?.data[0]?.type);
				formik.setFieldValue('date', res?.data?.data[0]?.date);
			}).catch((error) => {
				console.log(error);
			})
		}
	}, [id])

	const formik = useFormik({
		initialValues: {
			title: '',
			date: null,
			type: ''
		},
		validationSchema: Yup.object(validationRules),


		onSubmit: async (values, { setSubmitting, setFieldError }) => {

			var values = {
				title: values.title,
				date: values.date,
				type: values.type,
			};

			if(id){
				axiosPrivate
				.put(`holiday/update${id ? `/${id}` : ``}`, values)
				.then((response) => {
					showSuccessToast(response?.data?.message);
					navigate('/admin/holiday');
				})
				.catch((error) => {
					if (error.response) {

						if (error.response.status === 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
			}else{
				axiosPrivate
				.post(`holiday`, values)
				.then((response) => {
					
					showSuccessToast(response?.data?.message);
					navigate('/admin/holiday');
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status === 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
			}
		},
	});

	return (
		<>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong style={{ fontWeight: '700', fontSize: '18px' }}>
											Holiday :
										</strong>
									</div>
									<div className="card-body">
										<div className="row clearfix">
											{/* title*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Title:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="title"
														name="title"
														type="text"
														className="form-control"
														placeholder="Enter title here *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.title}
													/>
													{formik.touched.title && formik.errors.title ? (
														<div className={classes.invalidDataError}>
															{formik.errors.title}
														</div>
													) : null}
												</div>
											</div>
											{/* date */}
											<div className="col-md-6 col-sm-12">
												<label>
													Date:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="date"
														name="date"
														type="date"
														className="form-control"
														placeholder="Enter Date *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.date}
														max="9999-12-31"
														min={new Date().toISOString().split("T")[0]} 
													/>
													{formik.touched.date && formik.errors.date ? (
														<div className={classes.invalidDataError}>
															{formik.errors.date}
														</div>
													) : null}
												</div>
											</div>
											{/* date */}
											<div className="col-md-6 col-sm-12">
												<label>
													Type:<span className="required">*</span>
												</label>
												<div className="form-group">
													<select
														className="form-control show-tick"
														id="type"
														name="type"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.type}
													>
														<option value="" selected disabled>Select Type</option>
														<option value="fixed">Fixed</option>
														<option value="optional">Optional</option>
													</select>
													{formik.touched.type && formik.errors.type ? (
														<div className={classes.invalidDataError}>
															{formik.errors.type}
														</div>
													) : null}
												</div>
											</div>

											<div className="col-md-12 col-sm-12 submit-btn">
												<div className="form-group">
													<button
														type="button"
														className="btn btn-secondary"
														data-dismiss="modal"
														onClick={() => navigate('/admin/holiday')}
													>
														Close
													</button>
													<button
														type="submit"
														className="btn btn-primary ml-2"
													>
														{id ? 'Update' : 'Submit'}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddHoliday;
