import React from "react";
import { useForm } from "react-hook-form";
import axiosPrivate from '../../utils/http/axiosPrivate';
import { useNavigate } from "react-router-dom/dist";


function AddEsi() {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        axiosPrivate.post('/esi-create',data)
        .then((res)=>{
            navigate('/admin/esi');
        })
        .catch((error) => {
            if (error.response) {
                // showErrorToast('Internal server error');
                 console.log(error.response)
                if (error.response.status === 422) {
                    
                }
            }
        })
    }
    

  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                      Add ESI Details
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                        Employee ESI Percent:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="emp_code"
                            name="esi_employee_percent"
                            type="number"
                            className="form-control"
                            placeholder="Employee Esi Percent"
                            step="0.01"
                            {...register("esi_employee_percent", {required: true})}
                          />
                       {errors.esi_employee_percent && <p style={{color:'red',fontSize:'smaller'}}>Employee Esi Percent Required</p>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                        Employer ESI Percent:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="emp_code"
                            name="esi_employer_percent"
                            type="number"
                            className="form-control"
                            placeholder="Employer ESI Percent"
                            step="0.01"
                            {...register("esi_employer_percent", {required: true})}
                          />
                           {errors.esi_employer_percent && <p style={{color:'red',fontSize:'smaller'}}>Employer ESI Percent Required</p>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                        Employee ESI Fixed Amount:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="esi_employee_fixed_amount"
                            name="esi_employee_fixed_amount"
                            type="number"
                            className="form-control"
                            placeholder="Employee ESI Fixed Amount"
                            {...register("esi_employee_fixed_amount", {required: true,})}
                          />
                          {errors.esi_employee_fixed_amount && <p style={{color:'red',fontSize:'smaller'}}>Employee ESI Fixed Amount Required</p>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                        Employer ESI Fixed Amount:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="esi_employer_fixed_amount"
                            name="emp_code"
                            type="number"
                            className="form-control"
                            placeholder="Employer ESI Fixed Amount"
                            {...register("esi_employer_fixed_amount", {required: true, })}
                          />
                           {errors.esi_employer_fixed_amount && <p style={{color:'red',fontSize:'smaller'}}>Employer ESI Fixed Amount Required</p>}
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 submit-btn">
												<div className="form-group">
													<button
														type="button"
														className="btn btn-secondary"
														data-dismiss="modal"
														onClick={() => navigate('/admin/esi')}
													>
														Close
													</button>
													<button
														type="submit"
														className="btn btn-primary ml-2"
													>
														Submit
													</button>
												</div>
											</div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEsi;
