import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../../Toaster";
import axiosPrivate from "../../../utils/http/axiosPrivate";
import classes from "./addcompanyForm.module.css";

//mobile validation check rajex
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const panRegExp = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  const gstRegExp = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[A-Z0-9]{1}$/;

// validation
const validationRules = {
  name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Company name is required"),
  mobile_no: Yup.string()
    .required("Mobile number is required")
    .matches(phoneRegExp, "Mobile number is not valid")
    .min(10, "Mobile number must be of 10 digits")
    .max(10, "Mobile number must be of 10 digits"),
  email: Yup.string()
    .matches(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, "Invalid email format.")
    .required("Email is required"),
  address: Yup.string().required("Address is required"),
  gst_number: Yup.string().required("GST number is required").matches(gstRegExp, "GST number is not valid")
  .min(15, "GST number must be of 15 digits")
  .max(15, "GST number must be of 15 digits"),
  type: Yup.string().required("Type is required"),
  owner: Yup.string().required("Owner details is required"),
  pan_no: Yup.string().required("PAN number is required").matches(panRegExp, "PAN number is not valid")
  .min(10, "PAN number must be of 10 digits")
  .max(10, "PAN number must be of 10 digits"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  pincode: Yup.string().required("Pincode is required"),
};

const AddCompanyForm = () => {
  const { id } = useParams();
  const [filedata, setFileData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axiosPrivate
      .get(`company/${id}`)
      .then((response) => {
        showSuccessToast({ message: "Company details fetched" });
        formik.setValues(response?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          // showErrorToast('Internal server error');
          console.log(error.response);

          if (error.response.status === 422) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              formik.setFieldError(
                error.response.data.errors[i].param,
                error.response.data.errors[i].msg
              );
            }
          }
        }
      });
  }, []);

  const formik = useFormik({
    //name,address,gst_number,mobile_no,type,owner,pan_no,email,logo
    initialValues: {
      name: "",
      email: "",
      mobile_no: "",
      type: "",
      address: "",
      state: "",
      city: "",
      pincode: "",
      gst_number: "",
      owner: "",
      pan_no: "",
      logo: null,
    },
    validationSchema: Yup.object(validationRules),

    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("mobile_no", values.mobile_no);
      // formData.append("secondary_mobile",  values.mobile_no);
      formData.append("type", values.type);
      formData.append("address", values.address);
      formData.append("state", values.state);
      formData.append("city", values.city);
      formData.append("pincode", values.pincode);
      formData.append("gst_number", values.gst_number);
      formData.append("owner", values.owner);
      formData.append("pan_no", values.pan_no);
      formData.append("logo", filedata);

      axiosPrivate
        .put(`company/update/${id}`, formData)
        .then((response) => {
          showSuccessToast("Company updated Successfully");
          navigate("/admin/companyform");
        })
        .catch((error) => {
          if (error.response) {
            // showErrorToast('Internal server error');
            console.log(error.response);

            if (error.response.status === 422) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                formik.setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
      //   alert(JSON.stringify(values, null, 2));
    },
  });

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
  };
  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                      Company Details :
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      {/* name */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Company Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder=" Enter Company Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* email */}
                      <div className="col-md-6 col-sm-12">
                        <label>
                          Email:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Enter Email Id *"
                            onChange={(e) => formik.setFieldValue('email', e.target.value.toLowerCase())}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* primary mobile number */}
                      <div className="col-md-6 col-sm-12">
                        <label>
                          Mobile Number:
                          <span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="mobile_no"
                            name="mobile_no"
                            type="tel"
                            className="form-control"
                            placeholder="Enter Mobile Number *"
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile_no}
                            maxLength={10}
                          />
                          {formik.touched.mobile_no &&
                          formik.errors.mobile_no ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.mobile_no}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* secondary mobile number */}

                      {/* {formik.touched.secondary_mobile &&
													formik.errors.secondary_mobile ? (
														<div className={classes.invalidDataError}>
															{formik.errors.secondary_mobile}
														</div>
													) : null} */}

                      {/* type */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Type:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="type"
                            name="type"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.type}
                          >
                            <option value="" selected disabled>
                              Select a Type
                            </option>
                            <option value="IT">IT</option>
                            <option value="Construction">Construction</option>
                            <option value="Mining">Mining</option>
                            <option value="Finance">Finance</option>
                            <option value="Healthcare">Healthcare</option>
                            <option value="Retail">Retail</option>
                            <option value="Manufacturing">Manufacturing</option>
                            <option value="Transportation">
                              Transportation
                            </option>
                            <option value="Education">Education</option>
                          </select>
                          {/* <input
														id="type"
														name="type"
														type="text"
														className="form-control"
														placeholder="Enter type "
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.type}
													/> */}
                          {formik.touched.type && formik.errors.type ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.type}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* owner */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Owner Details:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="owner"
                            name="owner"
                            type="text"
                            className="form-control"
                            placeholder="Enter Owner Details *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.owner}
                          />
                          {formik.touched.owner && formik.errors.owner ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.owner}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* gst_number */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          GST Number:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="gst_number"
                            name="gst_number"
                            type="text"
                            className="form-control"
                            placeholder="Enter GST Number *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.gst_number}
                            maxLength={15}
                          />
                          {formik.touched.gst_number &&
                          formik.errors.gst_number ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.gst_number}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* pan_no */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          PAN Number:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="pan_no"
                            name="pan_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter PAN Number *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pan_no}
                            maxLength={10}
                          />
                          {formik.touched.pan_no && formik.errors.pan_no ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.pan_no}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* address */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Address:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="address"
                            name="address"
                            type="text"
                            className="form-control"
                            placeholder="Enter Address *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address}
                          />
                          {formik.touched.type && formik.errors.address ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.address}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* state dropdown */}
                      <div className="col-md-6 col-sm-12">
                        <label>
                          State:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            className="form-control "
                            type="text"
                            id="state"
                            name="state"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Enter State *"
                            value={formik.values.state}
                          />
                          {formik.touched.state && formik.errors.state ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.state}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label>
                          City:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            className="form-control"
                            id="city"
                            name="city"
                            onChange={formik.handleChange}
                            placeholder="Enter City *"
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.city}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <label>
                          Pincode:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            className="form-control"
                            placeholder="Enter Pincode *"
                            name="pincode"
                            id="pincode"
                            type="tel"
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.pincode}
                            maxLength={6}
                          />
                          {formik.touched.pincode && formik.errors.pincode ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.pincode}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>Logo:</label>
                        <div className="form-group">
                          <input
                            id="logo"
                            name="logo"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            placeholder="Enter type "
                            onChange={(e) => setFileData(e.target.files[0])}
                          />
                        </div>
                        {formik.values?.logo && (
                          <img
                            width={100}
                            height={100}
                            src={`${process.env.REACT_APP_COMMON_IMAGE_URL}company/${formik.values?.logo}`}
                            alt="Profile Preview"
                            className="preview-image"
                          />
                        )}
                      </div>

                      <div className="col-md-12 col-sm-12 submit-btn">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => navigate("/admin/companyform")}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                           Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCompanyForm;
