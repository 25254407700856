import { useEffect, useState } from "react";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast } from "../../../Toaster";
import DataTable2 from "../../../context/components/DataTable2/index";
import axiosPrivate from "../../../utils/http/axiosPrivate";

const LeaveList = () => {
  const [leaves, setLeaves] = useState([]);

  const headers = [
    { name: "Employee Name", field: "user_name", sortable: true, classKey: "" },
    { name: "Reason", field: "reason", sortable: true, classKey: "" },
    {
      name: "Avail Leaves",
      field: "avail_leaves",
      sortable: true,
      classKey: "",
    },
    {
      name: "Remaining Leaves",
      field: "remaining_leave",
      sortable: true,
      classKey: "",
    },
  ];

  const searchItems = ["user_name"];

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("leaves", options)
      .then((leaves) => {
        const leaveData = leaves?.data?.data.map((leave) => {
          leave["remaining_leave"] =
            parseFloat(leave.total_leaves) - parseFloat(leave.avail_leaves);
          return leave;
        });
        setLeaves(leaveData);
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          showErrorToast("Internal server error");
        }
      });
  }, []);

  return (
    <>
      <div className="mx-5">
        <div className="d-flex justify-content-between align-items-center mT-3">
          <ul className="nav nav-tabs page-header-tab text-dark">
            <li className="nav-item navSpace">
              <div className="card-header headerSpace">
                <div className="breadcrumb-area">
                  {/* header heading */}
                  <h1
                    className="card-title text-dark"
                    style={{ fontWeight: "700", fontSize: "18px" }}
                  >
                    Leave
                  </h1>

                  {/* header routing */}
                  <ol className="headerroutes breadcrumb m-0 bg-none ">
                    <li className="item color-gray">|</li>
                    <li className="item mx-2">
                        Home
                    </li>
                    <li className="item">
                      <FaGreaterThan style={{ fontSize: "14px" }} />
                    </li>
                    <li className="item mx-2 mt-1" style={{ fontSize: "14px" }}>
                      Leave list
                    </li>
                  </ol>
                </div>
              </div>
            </li>
          </ul>

          {/* right side add button */}
          <div className="header-action">
            {/* {authPermissions.includes('Staff-Create') ? ( */}
            <Link to="/admin/leave-form/add" className="text-decoration-none">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fe fe-plus mr-2" />
                Add
              </button>
            </Link>
            {/* ) : null} */}
          </div>
        </div>
      </div>

      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    {
                      <DataTable2
                        lists={leaves}
                        headers={headers}
                        searchItems={searchItems}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveList;
