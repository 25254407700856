import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosPrivate from "../../../utils/http/axiosPrivate";
// import AlertContext from "../../../context/Alert/AlertContext";
import { FaGreaterThan } from "react-icons/fa";
import { showErrorToast, showSuccessToast } from "../../../Toaster";
import 'react-toastify/dist/ReactToastify.css'; 
import SalaryForm from "./SalaryForm";
import LoanForm from "./LoanForm";
const Advance = () => {
 
  const [userState, setUserState] = useState([]);
  const [advanceType, setAdvanceType] = useState("salary");
  const [showForm, setShowForm] = useState(true);
  const fetchData = (type) => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("advance", options)
      .then((advanceData) => {
         
        setUserState(advanceData?.data?.data);
        showSuccessToast("Details fetched successfully");
      })
      .catch((error) => {
        if (error.response) {
          showErrorToast("Failed to fetch advance data");
        }
      });
  };
  const handleRadioChange = (type) => {
    setAdvanceType(type);
    setShowForm(true);
    fetchData(type);
  };
  useEffect(() => {
    fetchData(advanceType);
  }, [advanceType]);

  const salaryData = userState.filter((res) => res.advance_type === "salary");
  const loanData = userState.filter((res) => res.advance_type === "loan");

  return (
    <>
      <div className="mx-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <ul className="nav nav-tabs page-header-tab">
            <li className="nav-item">
              <div className="card-header">
                <strong className="top-left-heading">Advance List</strong>
                <ol className="headerroutes breadcrumb m-0 bg-none">
                  <li className="item color-gray">|</li>
                  <li className="item">
                    <Link to="/admin" className="route-heading item mx-2">
                      Dashboard
                    </Link>
                  </li>
                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">
                    <Link to="/admin/advance">Advance List</Link>
                  </li>
                </ol>
              </div>
            </li>
          </ul>
          <div className="header-action">
            <Link to="addadvance" className="text-decoration-none">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fe fe-plus mr-2" />
                Add
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="advance-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <div className="form-group d-flex align-items-center">
                        <label className="mr-4">
                          <input
                            type="radio"
                            value="salary"
                            checked={advanceType === "salary"}
                            onChange={() => handleRadioChange("salary")}
                          />
                          Salary
                        </label>
                        <label>
                          <input
                            type="radio"
                            value="loan"
                            checked={advanceType === "loan"}
                            onChange={() => handleRadioChange("loan")}
                          />
                          Loan
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showForm &&
        ((advanceType === "salary" && <SalaryForm salaryData={salaryData} />) ||
          (advanceType === "loan" && <LoanForm loanData={loanData} />))}
    </>
  );
};
export default Advance;