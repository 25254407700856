import { Fragment, useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/AuthContext";

const AuthMain = () => {

  const { userDetails } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (userDetails?.token) {
      console.log("userDetail", userDetails);
      navigate('admin/user');
    }
    else {
      navigate('/auth/signin')
    }
  }, [])
  return (
    <Fragment>
      <div className="font-montserrat">
        <div className="auth">
          <Outlet />
        </div>
      </div>
    </Fragment>
  );
};

export default AuthMain;
